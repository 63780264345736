import * as React from "react";
import { graphql } from "gatsby";
import { IAboutProps } from "../pageHelpers/About/AboutProps";
import { Container } from "../pageHelpers/About/AboutHelpers";
import Layout from "../components/global/Layout/Layout";
import { getParagraph } from "../utils/paragraphHelpers";
import SEO from "../components/global/SEO/SEO";


const About = (props: IAboutProps) => {
  const _page = props.data.page;
  const _site = props.data.allRlhsite.edges[0].node;
  const _paragraphs = props.data.page.relationships.paragraphs.map(getParagraph);
  return (<Layout {...{site: _site}}>
    <SEO title={_page?.title} description={_page?.field_meta_description} />
    <Container>{_paragraphs}</Container>
</Layout>);
};
export const pageQuery = graphql`
  query AboutQuery {
    page: nodePage(path: { alias: { eq: "/v1/about" } }) {
      id
      title
      field_meta_description
      relationships {
        paragraphs: field_sections {
          type: __typename
            ...ParagraphPageBanner
            ...ParagraphHistory
            ...ParagraphPageIntro
            ...ParagraphMediaTiles
            ...ParagraphTeaserList
            ...ParagraphMediaCta
            ...ParagraphOurBrands
            
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: {machine_name: {eq:"sonesta"}}) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default About;